import { Masonry } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import router from "next/router";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper";
import { GetAllPostsQuery } from "../../lib/graphql/codegen/generated-client-wp";
import { formatExcerpt } from "../../utils/formatExcerpt";
import Image from "next/image";
import { useTranslation } from "react-i18next";

interface PostsHomeProps {
  data: GetAllPostsQuery;
}

const PostSlider = ({ data }: PostsHomeProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const matchLg = useMediaQuery(theme.breakpoints.up("lg"));
  const matchMd = useMediaQuery(theme.breakpoints.up("md"));
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));
  const posts = data.posts?.edges;
  const postCards =
    posts?.map(({ node }) => {
      const featuredImage = node.featuredImage?.node;
      return (
        <Card key={node.id} sx={{ maxWidth: 345, mx: 2 }}>
          {featuredImage?.sourceUrl && (
            <CardMedia title="Your title" sx={{ height: 140 }}>
              <div
                style={{ position: "relative", width: "100%", height: "100%" }}
              >
                <Image
                  src={featuredImage.sourceUrl}
                  alt={featuredImage.altText ?? ""}
                  fill
                  style={{ objectFit: "cover" }}
                  sizes={`(max-width: ${theme.breakpoints.values.xs}px) 100vw,
                  ${theme.breakpoints.values.sm}px) 50vw,  ${theme.breakpoints.values.md}px) 33vw,  25vw, `}
                />
              </div>
            </CardMedia>
            // <CardMedia
            //   component={
            //     <Image
            //       height="140"
            //       src={featuredImage.sourceUrl}
            //       alt={featuredImage.altText ?? ""}
            //     />
            //   }
            // />
          )}
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {node.title}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              dangerouslySetInnerHTML={{
                __html: formatExcerpt(node.excerpt) ?? "",
              }}
            />
          </CardContent>
          <CardActions sx={{ justifyContent: "center" }}>
            <Button
              size="small"
              onClick={() => router.push(`/blog/${node.slug}`)}
            >
              {t("slider_posts.discover")}
            </Button>
          </CardActions>
        </Card>
      );
    }) ?? [];
  return (
    <Swiper
      pagination={true}
      navigation={true}
      slidesPerView={matchLg ? 4 : matchMd ? 3 : matchSm ? 2 : 1}
      autoplay={true}
      modules={[Navigation, Autoplay, Pagination]}
      className="post-slider"
    >
      {postCards.map((item, n) => (
        <SwiperSlide key={n}>{item}</SwiperSlide>
      ))}
    </Swiper>
  );
};

export default PostSlider;
