import {
  Container,
  Typography,
  Grid,
  Stack,
  Box,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  SvgGardenBlock,
  SvgGardenFlower,
  SvgGardenTree,
  SvgGardenWater,
} from "../assets/svg";
import PostSlider from "../components/sliders/PostSlider";
import { apolloWpClient } from "../lib/graphql";
import { GetHomePostsQuery } from "../lib/graphql/codegen/generated-client-wp";
import { GetHomePosts } from "../lib/graphql/queries/wordpress/posts.graphql";
import { useRouter } from "next/router";
import HomeSlider from "../components/sliders/HomeSlider";
import Seo from "../components/others/Seo";

const items = [SvgGardenBlock, SvgGardenTree, SvgGardenFlower, SvgGardenWater];

interface HomeProps {
  data: GetHomePostsQuery;
}
export default function Home({ data }: HomeProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <>
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Seo
          title={t("page_home.seo.title") ?? undefined}
          description={t("page_home.seo.description") ?? undefined}
        />
        <HomeSlider />
        <Stack maxWidth="lg" px={2}>
          <Typography
            align="center"
            sx={{
              color: theme.palette.primary.dark,
              my: 6,
              fontWeight: theme.typography.fontWeightMedium,
              fontSize: {
                xs: theme.typography.h4.fontSize,
                md: theme.typography.h3.fontSize,
              },
            }}
          >
            {t("page_home.title")}
          </Typography>
          <Grid container spacing={4} mb={4}>
            {items.map((Item, index) => (
              <Grid
                item
                key={index}
                xs={12}
                md={3}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  alignItems: "center",
                  "&:hover .icon-garden": {
                    fill: theme.palette.secondary.main,
                  },
                  ".icon-garden": {
                    fill: theme.palette.primary.dark,
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    mb: 3,
                  }}
                >
                  <Item className="icon-garden" style={{ height: "80px" }} />
                  <Typography
                    variant="h4"
                    align="center"
                    sx={{
                      fontWeight: theme.typography.fontWeightMedium,
                      fontSize: theme.typography.h5.fontSize,
                    }}
                  >
                    {t(`page_home.items.${index}.title`)}
                  </Typography>
                </Box>
                <Typography
                  variant="subtitle1"
                  align="center"
                  sx={{
                    borderBottomStyle: "solid",
                    pb: 4,
                    borderBottomWidth: 1,
                    borderBottomColor: theme.palette.divider,
                  }}
                >
                  {t(`page_home.items.${index}.body`)}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Stack>
        <Stack
          sx={{
            backgroundColor: theme.palette.background.layout,
            backgroundSize: "cover",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
            pb: 2,
          }}
        >
          <Stack width="100%">
            <Typography
              variant="h3"
              align="center"
              sx={{
                // color: theme.palette.primary.contrastText,
                py: 4,
                fontWeight: theme.typography.fontWeightMedium,
              }}
            >
              {t("page_home.advices")}
            </Typography>
            <PostSlider data={data} />
          </Stack>
        </Stack>
      </Container>
    </>
  );
}

export async function getStaticProps() {
  const posts = await apolloWpClient.query<GetHomePostsQuery>({
    query: GetHomePosts,
  });
  return {
    props: {
      data: posts.data || [],
    },
  };
}
