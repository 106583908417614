import img1 from "/public/img/masonry_jardinerie/1.jpg";
import img2 from "/public/img/masonry_jardinerie/2.jpg";
import img3 from "/public/img/masonry_jardinerie/3.jpg";
import img4 from "/public/img/masonry_jardinerie/4.jpg";
import img5 from "/public/img/masonry_jardinerie/5.jpg";
import img6 from "/public/img/masonry_jardinerie/6.jpg";
import img7 from "/public/img/masonry_jardinerie/7.jpg";
import img8 from "/public/img/masonry_jardinerie/8.jpg";
import img9 from "/public/img/masonry_jardinerie/9.jpg";
import img10 from "/public/img/masonry_jardinerie/10.jpg";
import img11 from "/public/img/masonry_jardinerie/11.jpg";

export const images = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
];
