import { Box, Typography, Stack, Button } from "@mui/material";
import router from "next/router";
import { homeImages } from "../../assets/img";
import theme from "../../config/theme";
import Image from "next/image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper";
import { useTranslation } from "react-i18next";

const HomeSlider = () => {
  const { t } = useTranslation();
  const list = [
    <Box key={1}>
      <Image alt="pepi" src={homeImages[2]} priority />
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "80%",
          height: "100%",
          maxHeight: 300,
          // maxWidth: 1000,
          transform: "translate(-50%, -50%)",
          // background: "rgba(0,0,0, 0.4)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: "white",
            textAlign: "center",
            pt: 2,
            px: 2,
            fontSize: {
              md: theme.typography.h2.fontSize,
              sm: theme.typography.h3.fontSize,
              xs: theme.typography.h4.fontSize,
            },
            textShadow: "1px 1px 2px black, 0 0 1em #AB7928, 0 0 0.2em #AB7928",
            fontWeight: theme.typography.fontWeightBold,
          }}
        >
          {t("slider_home.0.title")}
        </Typography>
        <Stack flex={1} justifyContent="center">
          <Button
            color="secondary"
            variant="contained"
            onClick={() => router.push("/jardinerie")}
          >
            <Typography
              sx={{
                fontSize: {
                  md: theme.typography.h6.fontSize,
                },
                fontWeight: theme.typography.fontWeightBold,
              }}
            >
              {t("slider_home.0.button")}
            </Typography>
          </Button>
        </Stack>
      </Box>
    </Box>,
    <Box key={2}>
      <Image alt="fleur" src={homeImages[3]} priority />
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "80%",
          // maxWidth: 1000,
          transform: "translate(-50%, -50%)",
          // background: "rgba(0,0,0, 0.6)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: "white",
            textAlign: "center",
            py: 5,
            px: 2,
            fontSize: {
              md: theme.typography.h2.fontSize,
              sm: theme.typography.h3.fontSize,
              xs: theme.typography.h4.fontSize,
            },
            fontWeight: theme.typography.fontWeightBold,
            textShadow: "1px 1px 2px black, 0 0 1em #AB7928, 0 0 0.2em #AB7928",
          }}
        >
          {t("slider_home.1.title")}
        </Typography>
      </Box>
    </Box>,
  ];
  return (
    <Swiper
      pagination={true}
      navigation={true}
      autoplay={{ delay: 6000 }}
      speed={600}
      modules={[Navigation, Autoplay, Pagination]}
      className="home-slider"
    >
      {list.map((item, n) => (
        <SwiperSlide key={n}>{item}</SwiperSlide>
      ))}
    </Swiper>
    // </div>
  );
};

export default HomeSlider;
